import React from 'react';
import { Button, TitleCard } from '../ui';
import { DeleteIcon } from '../../icons';
import { NewDepartmentForm } from './new-department-form.component';
import { Client, Department, EUserRole } from '../../backend/careo-api';
import { AxiosInstance } from '../../utils';
import { useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import { useAuth } from '../../contexts/auth.context';
import { useModal } from '../../contexts/side-modal.context';
import { useConfirm } from '../../contexts/confirm-modal.context';
import { ChargesRatesList } from './charges-rates-list.components';
import { NewRateForm } from './new-rate-form.component';

type ClientDepartmentProps = {
  client: Client;
  getClientDetails: () => void;
};

export const ClientDepartments = ({
  client,
  getClientDetails,
}: ClientDepartmentProps) => {
  const { id } = useParams();
  const { user } = useAuth();

  const { openModal, closeModal } = useModal();
  const onClickCreate = () => {
    openModal({
      title: 'New Availability',
      component: (
        <NewDepartmentForm
          getClientDetails={getClientDetails}
          onCancel={() => closeModal()}
          data-testid="new-department-form"
        />
      ),
    });
  };

  const onClickCreateChargeRate = (department: Department) => {
    openModal({
      title: 'New Charge Rate',
      component: (
        <NewRateForm
          getClientDetails={getClientDetails}
          onCancel={() => closeModal()}
          data-testid="new-charge-rate-form"
          specialty={department.specialty}
        />
      ),
    });
  };

  const { openConfirm, closeConfirm } = useConfirm();
  const onDeleteConfirm = (item: Department) => {
    openConfirm({
      title: 'Delete Department',
      component: (
        <>
          Do you want to delete the department of
          <b data-testid="confirm-delete-department-name">
            {item?.firstNameAccount}
          </b>
          ?
        </>
      ),
      onConfirm: () => deleteDepartment(item),
    });
  };

  const isClientBelongsToUser =
    client?.user?._id === user?._id || user?.role === EUserRole.Admin;

  const deleteDepartment = async (recordToDelete: Department) => {
    await AxiosInstance.clients
      .clientsControllerDeleteDepartment(id!, recordToDelete?._id!)
      .then((response) => {
        getClientDetails();
        toast.success('Department removed successfully');
        closeConfirm();
      })
      .catch(() => {
        toast.error('Something went wrong');
      });
    closeConfirm();
  };

  return (
    <>
      <div className="info-card" data-testid="info-card">
        <div className="info-card-header" data-testid="info-card-header">
          <div className="info-card-title" data-testid="info-card-title">
            Departments List
          </div>
          {isClientBelongsToUser && (
            <Button
              type="primary"
              onClick={() => onClickCreate()}
              data-testid="add-department-btn"
            >
              Add new department
            </Button>
          )}
        </div>
        <hr data-testid="info-card-divider" />
        <div
          className="accordion"
          id="accordionPanelsStayOpenExample"
          data-testid="accordion"
        >
          {(client.departments ?? [])?.map((department, index) => {
            return (
              <div
                className="accordion-item"
                key={department._id}
                data-testid={`accordion-item-${index}`}
              >
                <h2
                  className="accordion-header"
                  id="panelsStayOpen-headingOne"
                  data-testid={`accordion-header-${index}`}
                >
                  <button
                    className="accordion-button"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target={`#panelsStayOpen-${index}`}
                    aria-expanded="true"
                    aria-controls={`panelsStayOpen-${index}`}
                    data-testid={`accordion-button-${index}`}
                  >
                    {department.specialty} #{index + 1}
                  </button>
                </h2>
                <div
                  id={`panelsStayOpen-${index}`}
                  className="accordion-collapse collapse show"
                  aria-labelledby="panelsStayOpen-headingOne"
                  data-testid={`accordion-collapse-${index}`}
                >
                  <div
                    className="accordion-body"
                    data-testid={`accordion-body-${index}`}
                  >
                    <div
                      className="info-card-content row mb-5"
                      data-testid="contact-section"
                    >
                      <div className="col-md-12">
                        <TitleCard data-testid="contact-title">
                          Contact
                        </TitleCard>
                      </div>
                      <div className="info-card-content row">
                        <div className="col-md-6 row">
                          <div
                            className="info-item-title col-4"
                            data-testid="contact-title"
                          >
                            Title
                          </div>
                          <div
                            className="info-item-data col-8"
                            data-testid="contact-title-value"
                          >
                            {department?.titleContact}
                          </div>
                        </div>
                        <div className="col-md-6 row">
                          <div
                            className="info-item-title col-4"
                            data-testid="contact-first-name"
                          >
                            First Name
                          </div>
                          <div
                            className="info-item-data col-8"
                            data-testid="contact-first-name-value"
                          >
                            {department?.firstNameContact}
                          </div>
                        </div>
                        <div className="col-md-6 row">
                          <div
                            className="info-item-title col-4"
                            data-testid="contact-last-name"
                          >
                            Last Name
                          </div>
                          <div
                            className="info-item-data col-8"
                            data-testid="contact-last-name-value"
                          >
                            {department?.lastNameContact}
                          </div>
                        </div>
                        <div className="col-md-6 row">
                          <div
                            className="info-item-title col-4"
                            data-testid="contact-job-title"
                          >
                            Job Title
                          </div>
                          <div
                            className="info-item-data col-8"
                            data-testid="contact-job-title-value"
                          >
                            {department?.jobTitleContact}
                          </div>
                        </div>
                        <div className="col-md-6 row">
                          <div
                            className="info-item-title col-4"
                            data-testid="contact-email"
                          >
                            Email Address
                          </div>
                          <div
                            className="info-item-data col-8"
                            data-testid="contact-email-value"
                          >
                            {department?.emailContact}
                          </div>
                        </div>
                        <div className="col-md-6 row">
                          <div
                            className="info-item-title col-4"
                            data-testid="contact-mobile"
                          >
                            Mobile Number
                          </div>
                          <div
                            className="info-item-data col-8"
                            data-testid="contact-mobile-value"
                          >
                            {department?.phoneNumberContact}
                          </div>
                        </div>
                        <div className="col-md-6 row">
                          <div
                            className="info-item-title col-4"
                            data-testid="contact-work-phone"
                          >
                            Work Phone Number
                          </div>
                          <div
                            className="info-item-data col-8"
                            data-testid="contact-work-phone-value"
                          >
                            {department?.workPhoneNumberContact}
                          </div>
                        </div>
                      </div>
                      <div className="col-md-6 row">
                        <div
                          className="info-item-title col-4"
                          data-testid="department-contact"
                        >
                          Department
                        </div>
                        <div
                          className="info-item-data col-8"
                          data-testid="department-contact-value"
                        >
                          {department?.departmentContact}
                        </div>
                      </div>
                    </div>
                    <div
                      className="info-card-content row mb-5"
                      data-testid="account-section"
                    >
                      <div className="col-md-12">
                        <TitleCard data-testid="account-title">
                          Account
                        </TitleCard>
                      </div>
                      <div className="info-card-content row">
                        <div className="col-md-6 row">
                          <div
                            className="info-item-title col-4"
                            data-testid="account-title"
                          >
                            Title
                          </div>
                          <div
                            className="info-item-data col-8"
                            data-testid="account-title-value"
                          >
                            {department?.titleAccount}
                          </div>
                        </div>
                        <div className="col-md-6 row">
                          <div
                            className="info-item-title col-4"
                            data-testid="account-first-name"
                          >
                            First Name
                          </div>
                          <div
                            className="info-item-data col-8"
                            data-testid="account-first-name-value"
                          >
                            {department?.firstNameAccount}
                          </div>
                        </div>
                        <div className="col-md-6 row">
                          <div
                            className="info-item-title col-4"
                            data-testid="account-last-name"
                          >
                            Last Name
                          </div>
                          <div
                            className="info-item-data col-8"
                            data-testid="account-last-name-value"
                          >
                            {department?.lastNameAccount}
                          </div>
                        </div>
                        <div className="col-md-6 row">
                          <div
                            className="info-item-title col-4"
                            data-testid="account-job-title"
                          >
                            Job Title
                          </div>
                          <div
                            className="info-item-data col-8"
                            data-testid="account-job-title-value"
                          >
                            {department?.jobTitleAccount}
                          </div>
                        </div>
                        <div className="col-md-6 row">
                          <div
                            className="info-item-title col-4"
                            data-testid="account-email"
                          >
                            Email Address
                          </div>
                          <div
                            className="info-item-data col-8"
                            data-testid="account-email-value"
                          >
                            {department?.emailAccount}
                          </div>
                        </div>
                        <div className="col-md-6 row">
                          <div
                            className="info-item-title col-4"
                            data-testid="account-mobile"
                          >
                            Mobile Number
                          </div>
                          <div
                            className="info-item-data col-8"
                            data-testid="account-mobile-value"
                          >
                            {department?.phoneNumberAccount}
                          </div>
                        </div>
                        <div className="col-md-6 row">
                          <div
                            className="info-item-title col-4"
                            data-testid="account-work-phone"
                          >
                            Work Phone Number
                          </div>
                          <div
                            className="info-item-data col-8"
                            data-testid="account-work-phone-value"
                          >
                            {department?.workPhoneNumberAccount}
                          </div>
                        </div>
                      </div>
                    </div>
                    <div
                      className="info-card-content row mb-5"
                      data-testid="bank-details-section"
                    >
                      <div className="col-md-12">
                        <TitleCard data-testid="bank-title">
                          Bank details
                        </TitleCard>
                      </div>
                      <div className="col-md-6 row">
                        <div
                          className="info-item-title col-4"
                          data-testid="bank-name"
                        >
                          Bank Name
                        </div>
                        <div
                          className="info-item-data col-8"
                          data-testid="bank-name-value"
                        >
                          {department?.bank?.name}
                        </div>
                      </div>
                      <div className="col-md-6 row">
                        <div
                          className="info-item-title col-4"
                          data-testid="bank-account-name"
                        >
                          Account Name
                        </div>
                        <div
                          className="info-item-data col-8"
                          data-testid="bank-account-name-value"
                        >
                          {department?.bank?.accountName}
                        </div>
                      </div>
                      <div className="col-md-6 row">
                        <div
                          className="info-item-title col-4"
                          data-testid="bank-account-number"
                        >
                          Account Number
                        </div>
                        <div
                          className="info-item-data col-8"
                          data-testid="bank-account-number-value"
                        >
                          {department?.bank?.accountNumber}
                        </div>
                      </div>
                      <div className="col-md-6 row">
                        <div
                          className="info-item-title col-4"
                          data-testid="bank-type"
                        >
                          Type
                        </div>
                        <div
                          className="info-item-data col-8"
                          data-testid="bank-type-value"
                        >
                          -
                        </div>
                      </div>
                      <div className="col-md-6 row">
                        <div
                          className="info-item-title col-4"
                          data-testid="bank-sort-code"
                        >
                          Sort Code
                        </div>
                        <div
                          className="info-item-data col-8"
                          data-testid="bank-sort-code-value"
                        >
                          {department?.bank?.sortNumber}
                        </div>
                      </div>
                    </div>
                    <div
                      className="info-card-content row mb-5"
                      data-testid="bank-details-section"
                    >
                      <div className="col-md-12">
                        <div
                          className="info-card-header"
                          data-testid="info-card-header"
                        >
                          <TitleCard data-testid="bank-title">
                            Charges / Rates
                          </TitleCard>

                          <Button
                            type="primary"
                            onClick={() => onClickCreateChargeRate(department)}
                            data-testid="add-rate-button"
                          >
                            Add new rate
                          </Button>
                        </div>
                      </div>
                      <ChargesRatesList
                        client={client}
                        getClientDetails={getClientDetails}
                        specialty={department.specialty}
                      />
                    </div>
                    <div
                      className="info-card-content mb-5 d-flex justify-content-end"
                      data-testid="delete-department-btn"
                    >
                      {isClientBelongsToUser && (
                        <Button
                          type="danger"
                          onClick={() => onDeleteConfirm(department)}
                          data-testid="delete-department-button"
                        >
                          <DeleteIcon data-testid="delete-icon" /> Delete
                          Department
                        </Button>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            );
          })}
          {!client.departments?.length && (
            <div
              className="text-center m-4"
              data-testid="no-department-message"
            >
              No departments added. Please try to add.
            </div>
          )}
        </div>
      </div>
    </>
  );
};
