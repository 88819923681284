import React, { useEffect, useState } from 'react';
import { CardContainer } from '../../ui';
import {
  BriefcaseIcon,
  MinusIcon,
  PlusIcon,
  TrendingIcon,
  UsersIcon,
} from '../../../icons';
import { ComplianceStatisticsContainer } from './compliance-statistics.style';
import { AxiosInstance, AxiosInstanceErrorResponse } from '../../../utils';
import { toast } from 'react-toastify';
import { GeneralAnalytics } from '../../../backend/careo-api';
import { PerformanceChart } from '../../dashboard/performance-chart';

export const ComplianceStatistics = () => {
  const [analytics, setAnalytics] = useState<GeneralAnalytics>();

  const getAnalyticsDetails = async () => {
    AxiosInstance.compliances
      .compliancesControllerGetUserAnalytics()
      .then((response) => {
        setAnalytics(response.data);
      })
      .catch((error: AxiosInstanceErrorResponse) => {
        toast.error(error.message);
      });
  };

  useEffect(() => {
    getAnalyticsDetails();
  }, []);

  return (
    <ComplianceStatisticsContainer data-testid="compliance-statistics-container">
      <CardContainer className="report-card" data-testid="report-card">
        <div className="report-container">
          <div className="items-container" data-testid="items-container">
            <div className="item" data-testid="pending-tasks-item">
              <div
                className="icon-container"
                data-testid="icon-container-pending"
              >
                <BriefcaseIcon />
              </div>
              <div
                className="data-container"
                data-testid="data-container-pending"
              >
                <div className="value" data-testid="pending-tasks-count">
                  {analytics?.inProgressTasks?.count}
                </div>
                <div
                  className="percentage"
                  data-testid="pending-tasks-percentage"
                >
                  <TrendingIcon />
                  {analytics?.inProgressTasks?.percentage}%
                </div>
              </div>
              <div className="title" data-testid="pending-tasks-title">
                Compliance tasks pending
              </div>
            </div>
            <div className="item" data-testid="passed-tasks-item">
              <div
                className="icon-container"
                data-testid="icon-container-passed"
              >
                <PlusIcon />
              </div>
              <div
                className="data-container"
                data-testid="data-container-passed"
              >
                <div className="value" data-testid="passed-tasks-count">
                  {analytics?.passedTasks?.count}
                </div>
                <div
                  className="percentage"
                  data-testid="passed-tasks-percentage"
                >
                  <TrendingIcon />
                  {analytics?.passedTasks?.percentage}%
                </div>
              </div>
              <div className="title" data-testid="passed-tasks-title">
                Compliance tasks passed
              </div>
            </div>
          </div>
          <div className="items-container" data-testid="items-container-2">
            <div className="item" data-testid="failed-tasks-item">
              <div
                className="icon-container"
                data-testid="icon-container-failed"
              >
                <UsersIcon />
              </div>
              <div
                className="data-container"
                data-testid="data-container-failed"
              >
                <div className="value" data-testid="failed-tasks-count">
                  {analytics?.failedTasks?.count}
                </div>
                <div
                  className="percentage danger"
                  data-testid="failed-tasks-percentage"
                >
                  <TrendingIcon />
                  {analytics?.failedTasks?.percentage}%
                </div>
              </div>
              <div className="title" data-testid="failed-tasks-title">
                Compliance tasks failed
              </div>
            </div>
            <div className="item" data-testid="expired-tasks-item">
              <div
                className="icon-container"
                data-testid="icon-container-expired"
              >
                <MinusIcon />
              </div>
              <div
                className="data-container"
                data-testid="data-container-expired"
              >
                <div className="value" data-testid="expired-tasks-count">
                  {analytics?.expiredTasks?.count}
                </div>
                <div
                  className="percentage danger"
                  data-testid="expired-tasks-percentage"
                >
                  <TrendingIcon />
                  {analytics?.expiredTasks?.percentage}%
                </div>
              </div>
              <div className="title" data-testid="expired-tasks-title">
                Compliance tasks expired
              </div>
            </div>
          </div>
        </div>
      </CardContainer>
      <CardContainer
        className="performance-card"
        data-testid="performance-card"
      >
        <PerformanceChart
          isLoading={false}
          chartData={[
            {
              label: 'Passed Tasks',
              value: analytics?.passedTasks?.count ?? 0,
              backgroundColor: '#27a376',
              borderColor: '#27a376',
            },
            {
              label: 'Pending',
              value: analytics?.inProgressTasks?.count ?? 0,
              backgroundColor: '#ffd023',
              borderColor: '#ffd023',
            },
            {
              label: 'Needs Attention',
              value:
                (analytics?.failedTasks?.count ?? 0) +
                (analytics?.expiredTasks?.count ?? 0),
              backgroundColor: '#ee2f2f',
              borderColor: '#ee2f2f',
            },
          ]}
          data-testid="performance-chart"
        />
      </CardContainer>
    </ComplianceStatisticsContainer>
  );
};
