import { IRoute } from '../types/routes.type';

export const ProjectsRoutes: IRoute[] = [
  // {
  //   path: `/${ERoute.PROJECTS}`,
  //   element: <ProjectsListPage />,
  // },
  // {
  //   path: `/${ERoute.PROJECTS}/:id`,
  //   element: <ProjectDetailsPage />,
  // },
  // {
  //   path: `/${ERoute.PROJECTS}/:id/${EProjectTabs.ROTAS}`,
  //   element: <ProjectDetailsPage />,
  // },
  // {
  //   path: `/${ERoute.PROJECTS}/:id/${EProjectTabs.GENERAL}`,
  //   element: <ProjectDetailsPage />,
  // },
  // {
  //   path: `/${ERoute.PROJECTS}/:id/${EProjectTabs.ACTIVITIES}`,
  //   element: <ProjectDetailsPage />,
  // },
  // {
  //   path: `/${ERoute.PROJECTS}/:id/${EProjectTabs.MATCHING}`,
  //   element: <ProjectDetailsPage />,
  // },
  // {
  //   path: `/${ERoute.PROJECTS}/:id/${EProjectTabs.FINANCIAL}`,
  //   element: <ProjectDetailsPage />,
  // },
  // {
  //   path: `/${ERoute.PROJECTS}/:id/${EProjectTabs.LEGAL}`,
  //   element: <ProjectDetailsPage />,
  // },
];
